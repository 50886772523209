import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Loader from "../../common/Loader";
import useSnackBars from "../../../hooks/useSnackbar";
import {
  OnResultDocument,
  useGetBestPracticeLazyQuery,
  useListIacBestPracticeControlsQuery,
  useUpdateIacBestPracticeMutation,
} from "../../../generated";
import HriCounter from "../common/HriCounter";
import LoadingButton from "@mui/lab/LoadingButton";
import QuestionIacBestPractice from "./QuestionIacBestPractice";
import { useCreateIacMilestone } from "../../../hooks/useCreateIacMilestone";
import JSZip, { file } from "jszip";
import { saveAs } from "file-saver";
import { useApolloClient } from "@apollo/client";
import StyledTableCell from "../../common/StyledTableCell";
import { string } from "yup";

interface RiskCount {
  hriCount: number;
  initialHriCount: number;
}

interface AnswerBestPracticeProps {
  tscanSummaryIds: number[];
  createdWorkloads: any;
  wafrArn: string;
  riskCounts: RiskCount;
  changeTab: (index: number) => void;
  setUnlockSteps: React.Dispatch<
    React.SetStateAction<{
      step2: boolean;
      step3: boolean;
      step4: boolean;
    }>
  >;
  process: string;
}

export default function AnswerBestPracticeIac({
  tscanSummaryIds,
  createdWorkloads,
  wafrArn,
  riskCounts,
  changeTab,
  setUnlockSteps,
  process,
}: AnswerBestPracticeProps) {
  const client = useApolloClient();
  const { setAlerts } = useSnackBars();

  const { data, loading } = useListIacBestPracticeControlsQuery({
    variables: {
      summaryId: tscanSummaryIds, // value for 'summaryId'
    },
    fetchPolicy: "no-cache",
    context: {
      apiName: "well_architected",
    },
  });
  const { createIacMilestone } = useCreateIacMilestone();
  const [updateIacBestPracticeMutation] = useUpdateIacBestPracticeMutation();
  const [getBestPractice] = useGetBestPracticeLazyQuery({
    context: {
      apiName: "template_scanner",
    },
  });

  const [rowSelection, setRowSelection] = useState([]);
  const [rowNames, setRowNames] = useState([]);
  const [noRemediation, setNoRemediation] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [switchBehavior, setSwitchBehavior] = useState("Off");
  const scrollRef = useRef<null | HTMLDivElement>(null);

  const download = async () => {
    // Initialize JSZip
    console.log(data);
    const zip = new JSZip();
    const failedFiles: string[] = []; // To keep track of failed downloads

    let checksWithTemplates = [
      "TSN2_AWS_10",
      "TSN_AWS_343",
      "TSN_AWS_312",
      "TSN_AWS_319",
    ];
    console.log(rowSelection);
    const promises = rowSelection
      .filter(
        (key) =>
          checksWithTemplates.includes(key["checkRef"]) &&
          key["resource"] !== ""
      )
      .map(async (key) => {
        // If templateType is determined, fetch the template URL
        console.log("Key is ", key);
        let fileFormat = "";
        if (key["checkType"] === "terraform") {
          fileFormat = "tf";
        } else {
          fileFormat = "yaml";
        }
        let processDocName = `best-practice-${key["checkRef"]}-${key["checkType"]}.${fileFormat}`;

        try {
          const bestPracticeResponse = await getBestPractice({
            variables: {
              checkType: key["checkType"], // value for 'checkType'
              resource: key["resource"], // value for 'resource'
              description: key["description"], // value for 'description'
              checkId: key["checkRef"], // value for 'checkId'
              fileFormat: fileFormat, // value for 'fileFormat'
            },
            fetchPolicy: "no-cache",
          });
          console.log(bestPracticeResponse.data?.getBestPractice);
          // Wait for the subscription to complete and get the file URL
          const fileUrl = await new Promise<string>((resolve, reject) => {
            if (bestPracticeResponse.data?.getBestPractice?.uuid) {
              client
                .subscribe({
                  query: OnResultDocument,
                  variables: {
                    id: bestPracticeResponse.data?.getBestPractice?.uuid,
                  },
                })
                .subscribe(
                  ({ data }: any) => {
                    const fileUrl = data?.onResult?.result;
                    if (fileUrl) {
                      resolve(fileUrl); // Successfully retrieved the file URL
                    } else {
                      reject(`File URL not found for ${key["checkRef"]}`);
                    }
                  },
                  (error: any) => {
                    console.error(error);
                    reject(error); // Subscription error
                  },
                  () => {
                    console.log("Subscription completed");
                  }
                );
            } else {
              reject("UUID not found for the best practice");
            }
          });

          // Fetch the file content from the URL
          const response = await fetch(fileUrl);
          if (!response.ok) {
            throw new Error(
              `Failed to fetch ${processDocName}: ${response.statusText}`
            );
          }
          const blob = await response.blob();

          // Add file to the zip
          zip.file(processDocName, blob);
        } catch (error) {
          console.error(`Error downloading ${processDocName}: `, error);
          failedFiles.push(processDocName); // Track the failed file
        }
        return true;
      });

    // Wait for all files to be added to the zip
    let r = await Promise.all(promises);
    console.log(r);
    // If there are files in the zip, generate the zip and trigger download
    if (Object.keys(zip.files).length > 0) {
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, "BestPracticeTemplates.zip");
    }

    // If there are failed files, show a message to the user
    if (failedFiles.length > 0) {
      setAlerts([
        {
          severity: "error",
          msg: `The following files failed to download: \n${failedFiles.join(
            ", "
          )}`,
        },
      ]);
    } else if (Object.keys(zip.files).length === 0) {
      setAlerts([
        {
          severity: "info",
          msg: "No templates available to download.",
        },
      ]);
    }
  };

  //Handle that unlocks step 3 and locks step 2
  const handleUnlockSteps = () => {
    setUnlockSteps((prevState) => ({
      ...prevState,
      step3: true, // Lock step 3
      step4: false, // Unlock step 4
    }));
  };

  const handleConfirm = async () => {
    setActiveSpinner(true);
    let milestonename = "Best-Practice Recommendations";
    //Create the best-practice milestone
    const promises = Object.entries(createdWorkloads).map(
      async ([key, value]) => {
        const updateiacbestpractice = await updateIacBestPracticeMutation({
          variables: {
            workloadid: typeof value === "string" ? value : "", // value for 'workloadid'
            lens: "wellarchitected", // value for 'lens'
            controls: rowNames, // value for 'controls'
            awsWafrAccount: wafrArn,
          },
          context: {
            apiName: "well_architected",
          },
        });
        if (
          updateiacbestpractice?.data?.updateIacBestPractice?.status ===
          "COMPLETE"
        ) {
          let milestone_promise = await Promise.all([
            createIacMilestone(
              typeof value === "string" ? value : "",
              milestonename,
              wafrArn
            ),
          ]);
          return milestone_promise;
        }
      }
    );
    await Promise.all(promises);
    setAlerts([
      {
        severity: "success",
        msg: "Best-practice Recommendations Successfully Enabled.",
      },
    ]);
    //switch to step 3
    handleUnlockSteps();
    setDisabled(false);
    setActiveSpinner(false);
  };

  //If AccountType is Read_Only or no controls are selected to be remediated move to Step 3 when Next is clicked
  //Otherwise open Multi Automated Remediation Dialog
  const handleSubmit = async () => {
    if (noRemediation === true) {
      handleUnlockSteps();
      changeTab(3);
    } else {
      try {
        setDisabled(true);
        // Wait for both handleConfirm and download to finish
        await Promise.all([handleConfirm(), download()]);
        // Once both are complete, switch to the next tab
        changeTab(3);
      } catch (error) {
        console.error("Error processing handleConfirm or download:", error);
        setAlerts([
          {
            severity: "error",
            msg: "An error occurred during processing. Please try again.",
          },
        ]);
      }
    }
  };

  useEffect(() => {
    //If Controls are selected to be remediated then setNoRemediation to false
    //so that Multi Automated Remediation Modal opens
    if (rowNames.length > 0) {
      setNoRemediation(false);
    } else {
      setNoRemediation(true);
    }
  }, [rowNames]);

  const handleSwitch = () => {
    setSwitchBehavior((prev) => (prev === "Off" ? "All" : "Off")); // Toggle between "All" and "Independent"
  };

  return (
    <Container maxWidth="lg" component={Paper}>
      <Grid container sx={{ p: 1 }}>
        <Typography>
          NB: You will need to answer all questions in the one sitting due to
          the way that the workload is updated. If you would like any
          assistance, please reach out to secure@6pillars.ai
        </Typography>
        <Grid item xs md={12} sx={{ pt: 2 }}>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography fontWeight="bold" noWrap>
              Select All Recommendations?
            </Typography>
            <Typography fontWeight="bold" noWrap>
              &nbsp;
            </Typography>
            <Switch
              checked={switchBehavior === "All"} // Set checked state based on switchBehavior
              disabled={
                data?.listIacBestPracticeControls?.items.length === 0 ||
                activeSpinner
              }
              onChange={handleSwitch} // Handle switch toggling
              color="success"
            />
          </Box>
        </Grid>
        <Grid item xs md={12}>
          <HriCounter
            totalReducedHri={riskCounts.hriCount}
            initialHriCount={riskCounts.initialHriCount}
            questionnairetab={false}
          ></HriCounter>
        </Grid>
      </Grid>
      <Grid item xs md={4}></Grid>
      <Grid
        container
        sx={{ pb: 2, pt: 2, pl: 2 }}
        spacing={2}
        alignItems="center"
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>Template Scanner ID</StyledTableCell>
                <StyledTableCell>Descriptions</StyledTableCell>
                <StyledTableCell>Compliance Status</StyledTableCell>
                <StyledTableCell align="center">Select</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow
                  key={1}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell colSpan={6}>
                    <Loader></Loader>
                  </TableCell>
                </TableRow>
              )}
              {data?.listIacBestPracticeControls?.items.map((row, index) => (
                <QuestionIacBestPractice
                  key={index}
                  index={index}
                  rows={data?.listIacBestPracticeControls?.items}
                  row={row}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  rowNames={rowNames}
                  setRowNames={setRowNames}
                  switchBehavior={switchBehavior}
                  process={process}
                  activeSpinner={activeSpinner}
                ></QuestionIacBestPractice>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item md={12}>
          <Typography variant="body2" color="red">
            By clicking "Next," you agree to implement the recommended fixes via
            IaC. You will also download a zip file containing best-practice
            templates for each selected ID. The provided code snippets are
            AI-generated suggestions and should be tested by your team before
            incorporating them into your IaC templates.
          </Typography>
        </Grid>
        <Grid item md={10}></Grid>
        <Grid item md={2}>
          <Box ref={scrollRef}>
            <LoadingButton
              variant="contained"
              color="secondary"
              type="submit"
              disabled={disabled}
              loading={activeSpinner}
              onClick={handleSubmit}
              sx={{
                width: 157,
                height: 40,
                bgcolor: "secondary.main",
                "&:hover": {
                  bgcolor: "secondary.main",
                  color: "secondary.contrastText",
                },
              }}
              loadingIndicator={
                <CircularProgress color={"secondary"} size={22} />
              }
              autoFocus
            >
              Next
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
