const useRegions = () => {
  const supportedRegions = new Map([
    ["ap-southeast-2", "ap-southeast-2 (Sydney)"],
    ["ap-southeast-1", "ap-southeast-1 (Singapore)"],
    ["ap-south-1", "ap-south-1 (Mumbai)"],
    ["ap-northeast-1", "ap-northeast-1 (Tokyo)"],
    ["ap-east-1", "ap-east-1 (Hong Kong)"],
    ["us-west-1", "us-west-1 (N. California)"],
    ["us-west-2", "us-west-2 (Oregon)"],
    ["us-east-1", "us-east-1 (N. Virginia)"],
    ["us-east-2", "us-east-2 (Ohio)"],
    ["eu-central-1", "eu-central-1 (Frankfurt)"],
    ["eu-west-1", "eu-west-1 (Ireland)"],
    ["eu-west-2", "eu-west-2 (London)"],
    // ["me-south-1", "me-south-1 (Bahrain)"],
    // ["me-central-1", "me-central-1 (UAE)"],
  ]);
  return supportedRegions;
};

export default useRegions;
